import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import {
  PageHeader,
  Section,
  Content,
  Row,
  Col,
} from "../styles/StyledElements"
import { CardsSet } from "../components/cardLayout"
import { contentMapMarkdown } from "../components/pageUtilities"

export default function Forecasts({ data, location }) {
  const markdownMap = contentMapMarkdown(data.markdown.nodes)
  const title = "Forecasts"

  return (
    <Layout location={location}>
      <Seo title={title} />
      <PageHeader>
        <Content>
          <Row>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <Row>
            <Col>
              <div
                className={"description"}
                dangerouslySetInnerHTML={{
                  __html: markdownMap.get("description-forecasts").html,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Section>
      <Section>
        <Content>
          <CardsSet type={"forecast"} nodes={data.forecasts.nodes} />
          {/* <CardGrid>
          {data.forecasts.nodes?.map(node => (
            <CardForecast key={node.recordId} node={node}></CardForecast>
          ))}
        </CardGrid> */}
        </Content>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    forecasts: allAirtable(
      filter: { table: { eq: "Forecasts" } }
      sort: { order: ASC, fields: data___Display_Order }
    ) {
      nodes {
        recordId
        data {
          Name
          Category_Header
          Summary
          Image {
            localFiles {
              childImageSharp {
                # fluid(maxWidth: 1500) {
                #   ...GatsbyImageSharpFluid
                # }
                gatsbyImageData(sizes: "500")
              }
            }
          }
        }
      }
    }
    markdown: allMarkdownRemark(
      filter: { frontmatter: { section: { in: ["descriptions"] } } }
    ) {
      nodes {
        html
        frontmatter {
          ref
          section
          title
        }
      }
    }
  }
`
